const defaultArgs = {
  selectMode: 101, //101=picker image and video , 100=image , 102=video
  maxSelectCount: 10, //default 40 (Optional)
  maxSelectSize: 188743680, //188743680=180M (Optional)
};
export default {
  methods: {
    openMediaPicker(args = defaultArgs) {
      return new Promise((resolve, reject) => {
        window.MediaPicker.getMedias(
          args,
          async (medias) => {
            try {
              const allMedias = [];
              for (let i = 0; i < medias.length; i++) {
                const media = await this.onSuccess(medias[i].uri);
                allMedias.push(media);
              }
              resolve(allMedias);
            } catch (error) {
              reject(error);
            }
          },
          (err) => {
            reject(err);
          }
        );
      });
    },

    onSuccess(filePath) {
      return new Promise((resolve, reject) => {
        window.resolveLocalFileSystemURL(
          filePath,
          (entry) => {
            entry.file(
              (file) => {
                let reader = new FileReader();

                reader.onloadend = function(e) {
                  let imgBlob = new Blob([e.target.result], {
                    type: file.type,
                  });
                  let src = URL.createObjectURL(imgBlob);
                  resolve({
                    id: Math.random(),
                    src,
                    type: imgBlob.type,
                    file: imgBlob,
                  });
                };
                reader.readAsArrayBuffer(file);
              },
              (e) => {
                reject(e);
              }
            );
          },
          (err) => {
            reject(err);
          }
        );
      });
    },
  },
};
